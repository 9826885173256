@import '_variables';
@import '_mixins';

body {
  &.homepageBody {
    overflow: hidden;
    @include mobile() {
      // padding-top: 105px;
      padding-bottom: 50px;
    }
    #fp-nav {
      position: fixed;
      z-index: 100;
      top: 50%;
      transform: translateY(-30%);
      width: auto;
      left: 63px;
      margin: 0 !important;
      @include mobile() {
        display: block !important;
        width: 100%;
        left: 0;
        right: 0;
        top: auto;
        transform: translateY(0%);
        bottom: 0;
        background: transparent url('/images/bg-texture-2.jpg') no-repeat 0;
        height: 50px;
        text-align: center;
        overflow: hidden;
      }
      &:before,
      &:after {
        content: '';
        width: 4px;
        background: $army;
        height: 45%;
        position: absolute;
        z-index: 1;
        left: 8px;
        top: -50%;
        right: auto;
        bottom: auto;
        @include largeDesktop() {
          left: 6px;
        }
      }
      &:after {
        top: auto;
        bottom: -50%;
      }
      ul,
      li {
        list-style: none;
        text-align: center;
        a {
          .fp-sr-only {
            display: none;
            + span {
              display: inline-block;
              width: 20px;
              height: 20px;
              background: transparent;
              border: solid 2px $army;
              border-radius: 100%;
              margin: 1rem 0;
              @include largeDesktop() {
                width: 15px;
                height: 15px;
              }
            }
          }
          &.active {
            .fp-sr-only {
              + span {
                background: $army;
              }
            }
          }
        }
      }
      @include mobile() {
        ul,
        li {
          display: inline-block;
          padding: 0 10px;
        }
        ul {
          position: relative;
          &:after,
          &:before {
            content: '';
            width: 60%;
            height: 4px;
            background: $army;
            position: absolute;
            z-index: 1;
            left: -60%;
            right: auto;
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
          }
          &:after {
            left: auto;
            right: -60%;
          }
        }
      }
    }
  }
}
.homepage-slider-wrapper {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
.home-slide {
  position: relative;
  @include mobile() {
    overflow: hidden;
    &,
    .fp-tableCell {
      height: 100vh !important;
    }
  }
  .home-slide-image {
    position: absolute;
    z-index: 5;
    height: 0%;
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: visible;
    transition: all 1.2s;
    transition-timing-function: ease-in-out;
    .home-slide-image-holder {
      position: absolute;
      overflow: hidden;
      z-index: 10;
      height: 0%;
      width: 100%;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 1s;
      transition-timing-function: ease-in-out;
      transition-delay: 1.4s;
      img {
        position: absolute;
        width: auto;
        top: auto;
        margin: 0;
        bottom: -6%;
        left: 5%;
        right: 0;
        height: auto;
        width: 46%;
        @include largeDesktop() {
          width: 52%;
          left: 1%;
        }
        @include mobile() {
          width: 100%;
          left: -25%;
          bottom: 0;
        }
      }
      &.home-image-2 {
        img {
          bottom: -14%;
          @include mobile() {
            width: 100%;
            left: -12%;
            bottom: 0;
          }
        }
      }
      &.home-image-3 {
        img {
          @include mobile() {
            width: 100%;
            left: -5%;
            bottom: 0;
          }
        }
      }
    }
    .slider-contents {
      z-index: 100;
      position: absolute;
      width: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: 55%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include mobile() {
        width: 90%;
        top: 19%;
        transform: translateY(0%);
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }
    .slider-title-cta,
    .slider-title-sub,
    .slider-title-main {
      -webkit-animation-duration: 0.7s;
      animation-duration: 0.7s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: fadeOutDown;
      animation-name: fadeOutDown;
      animation-delay: 0ms;
      display: inline-block;
    }
    .slider-title-sub {
      font-size: 3rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      @include largeDesktop() {
        font-size: 2.5rem;
      }
      @include mediumDesktop() {
        font-size: 2rem;
      }
      @include smallDesktop() {
        font-size: 1.5rem;
      }
      @include mobile() {
        font-size: 1.6rem;
        padding-bottom: 5px;
      }
    }
    .slider-title-main {
      font-size: 7rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      line-height: 1;
      @include largeDesktop() {
        font-size: 5.5rem;
      }
      @include mediumDesktop() {
        font-size: 4rem;
      }
      @include smallDesktop() {
        font-size: 2.5rem;
      }
      @include mobile() {
        font-size: 4rem;
        padding-bottom: 5px;
      }
    }
    .slider-title-cta {
      display: inline-block;
      border-radius: 10px;
      border: solid 1px #ffffff;
      margin: 2rem 0.5rem;
      padding: 0.5rem 2rem;
      transition: all 0.4s;
      line-height: normal;
      &,
      &:link,
      &:visited {
        color: #fff;
      }
      &:hover,
      &:active {
        color: $army;
        background: #fff;
        cursor: pointer;
      }
    }
  }
  .home-slide-bg-color {
    position: absolute;
    width: 60%;
    height: 100%;
    left: auto;
    right: 0;
    z-index: 0;
    bottom: 0;
    top: 0;
    background: $putty;
    @include mobile() {
      width: 100%;
    }
  }
  .home-slide-bg {
    position: relative;
    width: 60%;
    height: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: -70%;
    background: $army url('/images/main-bg.jpg') no-repeat right top;
    background-size: cover;
    transition: all 0.5s;
    transition-delay: 0.8s;
    z-index: 1;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    justify-content: center;
    text-align: left;
    @include mobile() {
      width: 100%;
      margin-right: -100%;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      background: $army;
      transition: all 0.7s;
      transition-delay: 1.2s;
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    &:before {
      right: 0%;
      background: $putty;
      left: auto;
      z-index: -1;
      transition-delay: 1.8s;
      display: none;
    }
  }
  &.active {
    .home-slide-image {
      height: 100%;
      .home-slide-image-holder {
        height: 100%;
      }
      .slider-title-sub {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
        animation-delay: 1s;
      }
      .slider-title-main {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
        animation-delay: 1.4s;
      }
      .slider-title-cta {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
        animation-delay: 1.6s;
      }
    }
    .home-slide-bg {
      margin-right: 0;
      &:before {
        left: 0;
        height: 0;
      }
      &:after {
        width: 0%;
      }
    }
  }
}
